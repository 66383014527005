import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "PCB Layers",
  "description": "PCBs are made up of layers that fulfill certain tasks. Copper as the conductor, lamination for adhesive, solder mask as the insulation layer, and silkscreen as the guide.",
  "author": "Nadya Lukman",
  "categories": ["electronics"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": [""],
  "relatedArticles": [""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A PCB or Printed Circuit Board minimizes circuit setups from having components connected through copper wires and breadboards, to simply becoming compact, copper printed boards. These circuit boards should have different components to operate: this includes the components themselves, the conductors in the form of copper tracks and pads, insulators in the form of solder masks, also labelings that are done on the silkscreen.`}</p>
    <h2 {...{
      "id": "mechanical-layer"
    }}>{`Mechanical Layer`}</h2>
    <p>{`The mechanical layer of a `}<a parentName="p" {...{
        "href": "/articles/PCB-Software-File-Type/#Mechanical-Layer"
      }}>{`PCB design file`}</a>{` should contain the following attributes:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The precise PCB outline/contour`}</p>
        <p parentName="li">{`This could be done by following these rules:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Draw the PCB outline accurately at a 1:1 ratio`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`All relevant dimensions and some specific tolerances should be included, with the holes in the PCB as a reference for marking`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Contour is a closed figure`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The exact position and size of some internal grooves or cuts.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Some specific tolerance requirements.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It can also include some customized panel information, such as drilling patterns, hole position information, layer stacking, positioning holes, special production requirements, etc.`}</p>
      </li>
    </ol>
    <p>{`The PCB outline or contour is the shape in which the PCB is cut off from the material. The cutting process is generally done by `}<a parentName="p" {...{
        "href": "/articles/CNC/#milling"
      }}>{`milling`}</a>{`. The milling tool has the smallest size of 2 mm blade, thus the minimum inner radii of the PCB is 1 mm.`}</p>
    <p>{`The mechanical layer files also indicate the location and sizes of the slots, cut-outs, and `}<a parentName="p" {...{
        "href": "/articles/Vias-Holes/#through-holes"
      }}>{`holes`}</a>{`.  Cut-outs are the shape of the grooves that can't be cut using the milling cutter. The width of slots is determined by the tool's diameter and is done by moving the cutter from one point to another point.`}</p>
    <p>{`Your slots should be indicated as a part of the device package and should be indicated in the drilling file provided to the manufacturer. Slots could also be marked on the main board design. On the other hand, cut-outs don't need any additional indication in files. They are present as the outline of the PCB, with the manufacturer to address the process.`}</p>
    <h2 {...{
      "id": "copper-layer"
    }}>{`Copper Layer`}</h2>
    <p>{`When designing multi-layered PCBs, the stackup rules must be heavily taken into account. In `}<a parentName="p" {...{
        "href": "/articles/Stackup-Design/"
      }}>{`stackup design`}</a>{`, the use of copper layers is always followed by an insulating layer, and optionally an adhesive or laminating layer. The configuration of these copper layers is however different based on their placements. They can be categorized as the inner and outer layers of the PCB.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.27413127413127%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABcRAAAXEQHKJvM/AAABSUlEQVQY032PW0sCARCF/WW9dXsoCCLKGyGGaGWUK6kEPbRriYIlGl4IlNqoyEXd1U0rC3oo6B+kYS+1ZhpW2MNqnnDREqQODDNzYD7OyJrNJjrVUuD2CsbkIcxpBsvpKNaycVCXKZAXCdgyDIiTKEz8MaynMbx+1qSbzm1Lsh+jbd7QFsRX+5C0D4OjBsBRgzj3KMFvjIAl+5G0D0mdd4yiVi22gV+/wO6EoiiC23TBp1EhZJhBUK9FZEEH2kaAJuYQMmglL6DTYMeox3u53JuwG1ivN3DtJxGbHwNnloM1TYK3KJClZpFZmZZ2lphCYmkCKasatUrp75c75lFsHZR7HE6fCi6vElvbagQji/AFtXB5FXB6lXB45HD7Nai+vfyfsKWzxwrCd0+g8wL28wKihWcwhRKYhxIO7ovYywnYzQnS/CE2eoDfcw6KxyKa9fkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1426dac3b65d21598d25022058e16bc7/652f5/stackup.webp 259w", "/static/1426dac3b65d21598d25022058e16bc7/c29d2/stackup.webp 518w", "/static/1426dac3b65d21598d25022058e16bc7/77d2c/stackup.webp 1035w", "/static/1426dac3b65d21598d25022058e16bc7/ba7d2/stackup.webp 1553w", "/static/1426dac3b65d21598d25022058e16bc7/fbb1f/stackup.webp 1928w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1426dac3b65d21598d25022058e16bc7/a2ead/stackup.png 259w", "/static/1426dac3b65d21598d25022058e16bc7/6b9fd/stackup.png 518w", "/static/1426dac3b65d21598d25022058e16bc7/e3189/stackup.png 1035w", "/static/1426dac3b65d21598d25022058e16bc7/44d59/stackup.png 1553w", "/static/1426dac3b65d21598d25022058e16bc7/89557/stackup.png 1928w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1426dac3b65d21598d25022058e16bc7/e3189/stackup.png",
            "alt": "Stackup Design",
            "title": "Stackup Design",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`The outer layers are the ones that are exposed to the outside. That's where you see pads, vias, the solder mask (usually green) and a silkscreen print layer where information about the design of the PCB is presented. There will also be a surface finish layer in the exposed copper layers to keep it safe.`}</p>
    <p>{`The inner layers involve a substrate layer that constitutes the rigid core of your PCB. Copper alone would be too flexible. Copper layers allow for circuitry to happen(i.e. Electricity to pass through it.), whereas the prepreg layers provide the much needed insulation between the copper layers.`}</p>
    <p>{`The ground plane is an important part of designing a multilayered PCB, but is not essential for all PCB designs. Ground planes are commonly used to provide isolated voltage and/or signal return paths. They are also used in between signal layers to reduce interference and avoid crosstalks. `}</p>
    <p>{`Generally, the copper layers are defined as layers with etched copper on them. On these layers, pads and plated holes are configured. These are designed with the EDA software, where a circular diameter is plotted on the top or bottom plate, and a hole is generated with the same center point. Non-plated holes don't need any copper on the top or bottom layers, since they don't need to be conductive.`}</p>
    <h2 {...{
      "id": "lamination"
    }}>{`Lamination`}</h2>
    <hr></hr>
    <p>{`The laminating process refers to the process of binding the different PCB layers together, in order to protect and add strength to the different materials used.`}</p>
    <p>{`The most commonly used laminate materials are made up of fiberglass or other reinforcing materials impregnated with resin. The materials are usually covered and hot-pressed with copper foil. The choice of materials is as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`FR4`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`High TG FR4`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Rogers`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Taconic`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Arlon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Nelco`}</p>
      </li>
    </ul>
    <p>{`This laminating process (i.e. the addition of fiberglass) to the board does not have a significant effect on the PCB thickness. The greatest factor of the PCB's thickness is only the process of the glue application. An overflow will cause a significant thickness change to the overall PCB size.`}</p>
    <h2 {...{
      "id": "solder-mask"
    }}>{`Solder mask`}</h2>
    <p>{`A solder mask is a finish or varnish layer applied to prevent short circuits in between different nets due to solder placement, also to prevent oxidization of copper tracks in the PCB.`}</p>
    <p>{`Designing your solder mask layer can be done directly on the `}<a parentName="p" {...{
        "href": "/articles/PCB-Software-File-Type/"
      }}>{`EDA software`}</a>{`, paying special attention to the negative film. Usually, the solder mask layer is the green layer covering the PCB, but other colors, such as yellow, black, blue, red, white, purple, and matte green are also available to choose from.`}</p>
    <h2 {...{
      "id": "silkscreen"
    }}>{`Silkscreen`}</h2>
    <p>{`Silkscreening is the process where the manufacturer prints information on the solder mask layer to facilitate the processes of assembly, verification, and repair.`}</p>
    <p>{`Generally, the silkscreen is printed to show:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Test points`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Polarity marks`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Device mounting position`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Device reference number`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Company or maker's name, logo`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Assembly or usage explanatory text`}</p>
      </li>
    </ul>
    <p>{`The silkscreen is usually automatically generated by the `}<a parentName="p" {...{
        "href": "/articles/PCB-Software-File-Type/#pcb-design-files"
      }}>{`EDA software`}</a>{`, embedded in the `}<a parentName="p" {...{
        "href": "/articles/PCB-Software-File-Type/#cad-aperture-files"
      }}>{`Gerber files`}</a>{`, as a file usually named as silkscreen or overlay.`}</p>
    <p>{`The process of silkscreen printing is usually done according to these rules:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The orientation of silkscreen reading should only be in 2 directions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The silkscreen should be read from left to right, top to bottom, neatly and clearly.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A component tag must be printed near to the component`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The writing or symbol should not be covered by any component`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      